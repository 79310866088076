<template>
  <div class="container-page">
    <p class="title-inner">Туризм</p>
    <div class="mb-16">
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        @click="importExcel()"
      />
    </div>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <tabs v-on:select="tabSelected">
      <tab name="Число въездных туристских поездок иностранных граждан в Россию" :selected="true">
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <colgroup>
              <col width="20%" />
              <col width="50%" />
              <col width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th>Год</th>
                <th>Страна</th>
                <th>Количество, тыс чел</th>
              </tr>
              <tr>
                <th class="filter">
                  <FilterInput
                    type="year"
                    v-model="filter.in.year"
                    placeholder="Год"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    type="select2"
                    :select2-options="countries"
                    :select2-settings="select2Settings"
                    v-model="filter.in.countryId"
                    placeholder="(Все)"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="filter.in.valueFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter()"
                    />
                    <FilterInput
                      type="number"
                      v-model="filter.in.valueTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter()"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="page.in.items?.length && !isLoading">
              <tr v-for="item in page.in.items" :key="item.id">
                <td>{{ item.year }}</td>
                <td>{{ item.country.nameFull || item.country.name }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" colspan="4">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="4">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.in.pagesTotal > 1">
          <Pagination
            :page-count="page.in.pagesTotal"
            :current-page="page.in.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </tab>
      <tab name="Число выездных туристских поездок граждан России в зарубежные страны">
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <colgroup>
              <col width="20%" />
              <col width="50%" />
              <col width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th>Год</th>
                <th>Страна</th>
                <th>Количество, тыс чел</th>
              </tr>
              <tr>
                <th class="filter">
                  <FilterInput
                    type="year"
                    v-model="filter.out.year"
                    placeholder="Год"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    type="select2"
                    :select2-options="countries"
                    :select2-settings="select2Settings"
                    v-model="filter.out.countryId"
                    placeholder="(Все)"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="filter.out.valueFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter()"
                    />
                    <FilterInput
                      type="number"
                      v-model="filter.out.valueTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter()"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="page.out.items?.length && !isLoading">
              <tr v-for="item in page.out.items" :key="item.id">
                <td>{{ item.year }}</td>
                <td>{{ item.country.nameFull || item.country.name }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" colspan="4">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="4">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.out.pagesTotal > 1">
          <Pagination
            :page-count="page.out.pagesTotal"
            :current-page="page.out.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </tab>
    </tabs>
    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <ImportModal @doimport="doImport" @close="closeModal" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import ImportModal from '@/components/modals/ImportModal';

  import FilterInput from '../../../common/components/FilterInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import Api from '../api/index';

  export default {
    name: 'Citizens',
    components: { FilterInput, ImportModal, ModalComponent, ButtonStock, tabs, tab, DefaultFilter },
    mixins: [filtermanager],
    data() {
      return {
        routeBase: 'Citizens_xxx_RussiaService',
        paths: ['in', 'out'],
        currentPath: '',
        page: {
          in: {},
          out: {},
        },
        request: {
          in: {
            pageNumber: 1,
            pageSize: Constants.pageSize,
          },
          out: {
            pageNumber: 1,
            pageSize: Constants.pageSize,
          },
        },
        filter: {
          in: {
            year: null,
            countryId: null,
            valueFrom: null,
            valueTo: null,
          },
          out: {
            year: null,
            countryId: null,
            valueFrom: null,
            valueTo: null,
          },
        },
        countries: [],
        select2Settings: Constants.select2Settings,
        importInProgress: false,
        importModal: false,
        isLoading: false,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.currentPath = this.paths[0];
        this.loadDictionaries();
        this.loadPage();
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter[this.currentPath], this.request[this.currentPath]);
        Api.search(this.routeBase.replace('_xxx_', this.currentPath), request)
          .then((response) => {
            if (!append) {
              this.page[this.currentPath] = {};
              this.$nextTick(() => {
                this.page[this.currentPath] = response.data;
              });
            } else if (response.data) {
              this.page[this.currentPath].pageNumber = response.data.pageNumber;
              this.page[this.currentPath].items = this.page[this.currentPath].items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        Utils.searchNsi('countrylistwithdata', {})
          .then((response) => {
            this.countries = response.data.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        Api.downloadFile(this.routeBase.replace('_xxx_', this.currentPath), file)
          .then((response) => {
            console.log(response);
            Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
            this.loadPage();
            this.importInProgress = false;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      tabSelected(index) {
        this.currentPath = this.paths[index];
        if (!this.page[this.currentPath]?.items?.length) {
          this.loadPage(false);
        }
      },
      onPageChange(pageNumber, addToExist) {
        this.request[this.currentPath].pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.request[this.currentPath].pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      closeModal() {
        this.importModal = false;
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss">
  .filter {
    .range {
      display: flex;

      .input-group-wrapper {
        input {
          padding: 4px 8px;
          min-width: 80px;
          max-width: 80px;
        }
      }
    }
  }
</style>
